import { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const addGalleryWidget = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
): Promise<ComponentRef> => {
  const widgetRef = await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: '24c89560-0bba-45d6-b400-d102a138bd4e',
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      height: 300,
      width: 600,
      x: 0,
      y: 0,
    },
    presetIds: {
      style: 'variants-m41b1qc71',
      layout: 'variants-m41b1qc71',
    },
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'percentage',
          value: 50,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        margins: {
          top: {
            type: 'px',
            value: 0,
          },
        },
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
    },
  });

  return widgetRef;
};
